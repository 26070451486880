import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

if(import.meta.env.VITE_PUSHER_APP_KEY){

    console.log('Laravel Echo with pusher activated');
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        encrypted: true,
        wsHost: import.meta.env.VITE_PUSHER_HOST,
    });
    window.Pusher.logToConsole = true;
} else if(import.meta.env.VITE_REVERB_APP_ID){
    console.log('Laravel Echo with reverb activated');
    console.log(import.meta.env.VITE_REVERB_APP_ID);
    window.Echo = new Echo({
        broadcaster: 'reverb',
        key: import.meta.env.VITE_REVERB_APP_ID,
        wsHost: import.meta.env.VITE_REVERB_HOST,
        wsPort: import.meta.env.VITE_REVERB_PORT,
        wssPort: import.meta.env.VITE_REVERB_PORT,
        disableStats: true,
    });
}
else {
    console.error('Laravel Echo deactivated. Please set PUSHER_APP_KEY or VITE_REVERB_APP_ID in .env');
}
