import {Alpine as LivewireAlpine} from "../../vendor/livewire/livewire/dist/livewire.esm";
import type {Alpine as AlpineType, AlpineComponent} from "alpinejs";
import {Input, number, parse, string, union} from "valibot";

const Alpine: AlpineType = LivewireAlpine

const OptionValue = union([string(), number()], 'Value of a radio group option must be a string or number')
type OptionType = Input<typeof OptionValue> | null;


Alpine.data('radioGroup', (...parameters: unknown[]) => {
    const initialSelected = parameters[0] !== undefined ? parse(OptionValue, parameters[0]) : null;

    const options = [] as OptionType[];

    return ({
        options: options,
        selectedValue: initialSelected,
        get currentlySelectedIndex() {
            return this.options.indexOf(this.selectedValue);
        },
        select(selectedValue?: OptionType) {
            if(!selectedValue) {
                this.selectedValue = null;
                return;
            }
            const selectIndex = this.options.indexOf(selectedValue);

            const input = this.$refs['options-' + selectedValue];

            if (!(input instanceof Element)) {
                console.error('Radio group option not focusable')
                return;
            }

            if (input.hasAttribute('disabled')) {
                this.select(selectIndex < this.currentlySelectedIndex ? selectIndex - 1 : selectIndex + 1);
                return;
            }

            this.selectedValue = selectedValue;

            this.$nextTick(() => {
                input.focus()
                input.scrollIntoView({block: 'nearest'})
            });
        },
        selectNext() {
            this.select(this.options[this.currentlySelectedIndex + 1] );
        },
        selectPrevious() {
            this.select(this.options[this.currentlySelectedIndex - 1]);
        },
        radioButton: (...parameters: unknown[]) => {
            const value = parse(OptionValue, parameters[0]);
            return {
                ['@click'](this: AlpineComponent<any>) {
                    this.select(value);
                },
                'x-ref': 'options-' + value,
                ':aria-checked'(this: AlpineComponent<any>) {
                    return this.selectedValue === value ? 'true' : 'false'
                },
                role: 'radio',
                ':data-radio-state'(this: AlpineComponent<any>) {
                    return this.selectedValue === value ? 'checked' : 'unchecked'
                },
                'data-radio-value': value,
                ':tabindex'(this: AlpineComponent<any>) {
                    return this.selectedValue === value ? '0' : '-1'
                },
                ['@keydown.up'](this: AlpineComponent<any>, event: KeyboardEvent) {
                    event.preventDefault()
                    this.selectPrevious()
                },
                ['@keydown.down'](this: AlpineComponent<any>, event: KeyboardEvent) {
                    event.preventDefault()
                    this.selectNext()
                },
                ['@keydown.left'](this: AlpineComponent<any>, event: KeyboardEvent) {
                    event.preventDefault()
                    this.selectPrevious()
                },
                ['@keydown.right'](this: AlpineComponent<any>, event: KeyboardEvent) {
                    event.preventDefault()
                    this.selectNext()
                },
            }
        }
    });
});
