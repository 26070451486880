import {Alpine as AlpineType} from "alpinejs";
import {Alpine as LivewireAlpine} from "../../vendor/livewire/livewire/dist/livewire.esm";
import {addMinutes, differenceInMinutes, format, parse} from "date-fns";

const Alpine: AlpineType = LivewireAlpine

const durations = new Map()

Alpine.directive('duration-from', (el, {expression, modifiers}, {evaluateLater, effect, cleanup, Alpine}) => {
    const identifier = modifiers[1] ?? [expression, modifiers.join('')].join('-')
    let otherTime = evaluateLater(expression)
    const updateDurationHandler = (e: Event) => {
        if (!(e.target instanceof HTMLInputElement)) {
            return true;
        }

        const value = e.target.value

        updateDuration(value, e.target)
    }

    const updateDuration = (newTime: string, el: HTMLElement, defaultDuration?: string) => otherTime((otherTime) => {
        if(!isValidTime(newTime) || !isValidTime(otherTime)) {
            if(defaultDuration) {
                durations.set(identifier, parseInt(defaultDuration))
            }
            return;
        }

        const duration = durationInMinutes(otherTime, newTime)

        if(isNaN(duration) || !duration) {
            return;
        }

        durations.set(identifier, duration)
    })

    updateDuration(el.value, el, modifiers[0] ?? undefined)
    el.addEventListener('input', updateDurationHandler)

    effect(() => {
        otherTime((otherTime) => {
            if(!isValidTime(otherTime) || !isValidTime(el.value)) {
                return;
            }
            const durationInMinutes = durations.get(identifier);
            if(!durationInMinutes) {
                return;
            }

            const newTime = addMinutesToTime(otherTime as string, parseInt(durationInMinutes));

            if(el.value === newTime) {
                return;
            }
            el.value = newTime

            el.dispatchEvent(new Event('input', {
                bubbles: true,
            }))
        })
    })


    cleanup(() => {
        el.removeEventListener('input', updateDurationHandler, {
            capture: true,
        })
        durations.delete(identifier)
    })
});

function isValidTime(time: string) {
    const matches = time.match(/^(\d{2}):(\d{2})$/)

    if(matches === null) {
        return false;
    }

    const hours = parseInt(matches[1] as string)
    const minutes = parseInt(matches[2] as string)


    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59
}

function durationInMinutes(earlierTime: string, laterTime: string) {
    const now = new Date()
    return differenceInMinutes(parse(laterTime, 'HH:mm', now), parse(earlierTime, 'HH:mm', now))
}

function addMinutesToTime(time: string, minutes: number) {
    return format(addMinutes(parse(time, 'HH:mm', new Date()), minutes), 'HH:mm')
}
