console.log('BLINK Admin :)');
import.meta.glob([
  '../fonts/**',
]);
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import { Client } from 'typesense';
import './bootstrap'

import {createPopper} from '@popperjs/core';
window.Typesense = Client;
window.createPopper = createPopper;

import Fuse from 'fuse.js'

window.Fuse = Fuse;

window.Livewire = Livewire;
import VideoUpload from './filament/video-upload.js'

import 'livewire-sortable'

import './main'

import './tasks'
import './select-student'

import './single-combobox.ts'
import './single-search-input'
import './multi-combobox.ts'
import './calendar.tsx'
import './sentry'
import './radio-group.ts'
import './keep-durations.ts'


Alpine.data('videoUpload', VideoUpload);
Livewire.start()


document.addEventListener('trix-before-paste', function (e) {
    if (e.paste.hasOwnProperty('html')){
        let div = document.createElement("div");
        div.innerHTML = e.paste.html;
        e.paste.html = div.textContent;
    }
});
