import {Alpine as LivewireAlpine} from "../../vendor/livewire/livewire/dist/livewire.esm";
import {Alpine as AlpineType, AlpineComponent} from "alpinejs";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import deLocale from "@fullcalendar/core/locales/de";
import {addMinutes, addWeeks, endOfWeek, format, parse, setDefaultOptions, startOfWeek, subWeeks} from "date-fns";
import {Calendar, CalendarOptions} from "@fullcalendar/core";
import type {Admin, DateTimeString} from "../types/generated";
import {ComponentChildren, h} from "preact";
import {arrow, autoPlacement, autoUpdate, computePosition, offset, shift} from "@floating-ui/dom";
import {de} from "date-fns/locale";

setDefaultOptions({locale: de, weekStartsOn: 1})
type NewCalendarEvent = {
    id: string,
    start: DateTimeString,
    end: DateTimeString,
    type: 'new-event'
    appointmentOwner: {
        id: number,
        type: 'user' | 'car'
    }
    events: StoredCalendarEvents[]
}

type RawEvents = (Admin.AbsenceCalendarEvent
    | Admin.LessonCalendarEvent
    | Admin.ExamCalendarEvent
    | Admin.BlockerCalendarEvent
    | Admin.PublicHolidayCalendarEvent
    | Admin.WorktimeCalendarEvent
    | Admin.CourseTimeSlotCalendarEvent)
type StoredCalendarEvents = Omit<RawEvents, 'appointmentOwner'> & Required<Pick<RawEvents, 'appointmentOwner'>>;
type CalendarEvent =
    Omit<RawEvents, 'appointmentOwner'> & Required<Pick<RawEvents, 'appointmentOwner'>>
    | NewCalendarEvent
const Alpine: AlpineType = LivewireAlpine

type Filters = {
    selectedDate: string,
    selectedStudent: string,
    teachers: (string | number)[],
    cars: (string | number)[]
    selectedEventId: (string | null)
}


Alpine.data('calendar', (filters: Filters) => {
        let calendar = null as Calendar | null;
        return {
            events: [] as CalendarEvent[],
            anonymized: false,
            modifiedEvents: [] as CalendarEvent[],
            creationEvent: null as NewCalendarEvent | null,
            tooltipCleanup: null as (() => void) | null,
            revertLastMovement: null as (() => void) | null,
            isDragging: false,
            view: 'week',
            ...filters,
            init() {
                window.Echo.private('calendar.1234')
                    .listen('CalendarEventsUpdated', () => this.fetchEvents())
                if (!this.$refs.calendar) {
                    throw new Error('Define a ref="calendar" on the element that should contain the calendar inside the x-data="calendar" component')
                }
                calendar = new Calendar(this.$refs.calendar, this.calendarOptions());
                this.fetchEvents()
                    .then(() => {
                        if(this.selectedEvent) {
                            this.openOverviewTooltip(this.selectedEvent)
                        }
                    })
                calendar.render();
                this.$watch('anonymized', () => {
                    calendar?.refetchEvents()
                })
                this.$watch('selectedDate', (newDate) => {
                    if (typeof newDate !== 'string') {
                        return;
                    }
                    calendar?.gotoDate(newDate)
                    this.fetchEvents()
                })
                this.$watch('cars', () => {
                    this.fetchEvents(true)
                })
                this.$watch('teachers', () => {
                    this.fetchEvents(true)
                })
            },

            async fetchEvents(removeOldEvents = false) {
                const {start, end} = this.range();
                const events = await fetchEvents(
                    start,
                    end,
                    {
                        teachers: this.teachers,
                        cars: this.cars,
                    }
                )


                if (removeOldEvents) {
                    this.events = events;
                    calendar?.refetchEvents()
                    return;
                }
                const newEvents = events.filter((event) => !this.events.find((existingEvent) => existingEvent.id === event.id));

                if (newEvents.length === 0) {
                    return;
                }
                this.events = [...this.events, ...newEvents]
                calendar?.refetchEvents()
            },
            updateEvents({events}: { events: CalendarEvent[] }) {
                this.showModifiedCalendarForAllUsers(events)
                this.selectedEventId = this.mainEvent(events).id
                this.modifiedEvents = events
                if (!events[0]?.start) {
                    calendar?.refetchEvents()
                    return;
                }

                calendar?.refetchEvents()
                this.selectedDate = format(new Date(events[0]?.start), 'yyyy-MM-dd')
            },

            showModifiedCalendarForAllUsers(events: CalendarEvent[]) {
                events.forEach(event => {
                    if(event.appointmentOwner.type !== 'user') {
                        return;
                    }
                    if(this.teachers.find(teacher => String(teacher) === event.appointmentOwner.id.toString())) {
                        return;
                    }

                    this.teachers.push(event.appointmentOwner.id)
                })
            },

            mainEvent(events: CalendarEvent[]) {
                return events.find((event) => !('isSubEvent' in event && event.isSubEvent))
            },

            get selectedEvent() {
              return this.selectedEventId ? this.getEventById(this.selectedEventId) : null;
            },

            revertMovement() {
                if (!this.revertLastMovement) {
                    return;
                }
                this.revertLastMovement();
                this.revertLastMovement = null;
            },

            updateCreationEvents({events}: { events: StoredCalendarEvents[] }) {
                if (!this.creationEvent || !events[0]) {
                    return;
                }

                events[0].id = this.creationEvent.id
                this.showModifiedCalendarForAllUsers(events)
                this.creationEvent.events = events
                this.selectedEventId = events[0].id
                this.selectedDate = events[0].start.split('T')[0] as string
                calendar?.refetchEvents()
            },

            createEvent(event: NewCalendarEvent) {
                this.creationEvent = event

                this.selectedEventId = event.id
                this.$dispatch('open-calendar-tooltip', {
                    component: 'calendar.lessons.create',
                    arguments: {
                        start: event.start,
                        end: event.end,
                        studentId: this.selectedStudentId,
                        userId: event.appointmentOwner.type === 'user' ? event.appointmentOwner.id : null,
                        carId: event.appointmentOwner.type === 'car' ? event.appointmentOwner.id : null,
                    }
                });
                calendar?.refetchEvents()
            },

            storeEvents(event: { detail: { events: CalendarEvent[] } }) {
                this.events = this.uniqueEvents(this.events as CalendarEvent[], event.detail.events)
                this.modifiedEvents = []
                calendar?.refetchEvents()
                this.fetchEvents(true)
                this.$dispatch('close-calendar-tooltip')
            },

            deleteEvents(event: { detail: { eventIds: string[] } }) {
                this.events = this.events.filter(currentEvent => !event.detail.eventIds.includes(currentEvent.id ?? ''))
                this.modifiedEvents = []
                calendar?.refetchEvents()
                this.fetchEvents(true)
                this.$dispatch('close-calendar-tooltip')
            },
            getEventById(id: string) {
                return (this.allEvents().find(event => event.id === id || ('groupId' in event && event.groupId === id)) as unknown) as CalendarEvent | undefined;
            },

            uniqueEvents(events: CalendarEvent[], newEvents: CalendarEvent[]) {
                return [...events.filter(event => !newEvents.find(newEvent => newEvent.id === event.id || ('groupId' in newEvent && 'groupId' in event && newEvent.groupId === event.groupId))), ...newEvents]
            },

            addCreationEvent(events: CalendarEvent[]) {
                if (!this.creationEvent) {
                    return events;
                }
                if (this.creationEvent.events.length === 0) {
                    return [...events, this.creationEvent] as CalendarEvent[]
                }

                return [...events, ...this.creationEvent.events as CalendarEvent[]] as CalendarEvent[]
            },

            allEvents() {
                return removeNotSelectedCarEvents(
                    this.uniqueEvents(this.addCreationEvent(this.events as CalendarEvent[]), this.modifiedEvents as CalendarEvent[]),
                    this.cars
                );
            },

            currentDate() {
                return parse(this.selectedDate, 'yyyy-MM-dd', new Date())
            },

            range() {
                return {
                    start: startOfWeek(this.currentDate()),
                    end: endOfWeek(this.currentDate()),
                };
            },

            nextWeekButton() {
                return {
                    'x-on:click': () => {
                        this.selectedDate = format(addWeeks(this.currentDate(), 1), 'yyyy-MM-dd')
                    }
                }
            },

            prevWeekButton() {
                return {
                    'x-on:click': () => {
                        this.selectedDate = format(subWeeks(this.currentDate(), 1), 'yyyy-MM-dd')
                    }
                }
            },

            todayButton() {
                return {
                    'x-on:click': () => {
                        this.selectedDate = (new Date()).toISOString().split('T')[0] as string
                    }
                }
            },
            updateTooltip(anchor: HTMLElement, tooltip: HTMLElement, arrowElement: HTMLElement) {
                if (this.tooltipCleanup) {
                    this.tooltipCleanup();
                    this.tooltipCleanup = null;
                }
                this.tooltipCleanup = setupTooltip(anchor, tooltip, arrowElement);
            },

            viewTooltip() {
                return {
                    ['x-show'](this: AlpineComponent<any>) {
                        return this.tooltipCleanup !== null && !this.isDragging;
                    },
                    'x-ref': 'tooltip',
                }
            },

            closeTooltip() {
                this.deselectEvent()
            },

            deselectEvent() {
                this.creationEvent = null;
                this.selectedEventId =null;
                this.modifiedEvents = [];
                this.tooltipCleanup?.();
                this.tooltipCleanup = null;
                calendar?.refetchEvents()
            },

            openOverviewTooltip(event: CalendarEvent) {
                if(!('overviewComponent' in event)) {
                    return;
                }

                this.$dispatch('open-calendar-tooltip', {
                    component: event.overviewComponent.name,
                    arguments: event.overviewComponent.arguments
                })
            },

            calendarOptions() {
                return {
                    timeZone: 'Europe/Zurich',
                    plugins: [
                        interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin
                    ],
                    eventDurationEditable: false,
                    headerToolbar: {
                        left: '',
                        right: ''
                    },
                    views: {
                        timeGridOneDay: {
                            type: 'timeGrid',
                            duration: {days: 1},
                            buttonText: '1 day'
                        },
                        timeGridThreeDay: {
                            type: 'timeGrid',
                            duration: {days: 3},
                            buttonText: '3 day'
                        },
                    },
                    dayHeaderFormat: function (data) {
                        return format(data.date.marker, 'EE dd.MM');
                    },
                    initialDate: this.selectedDate,
                    initialView: 'timeGridWeek',
                    slotMinTime: '05:00:00',
                    slotMaxTime: '23:00:00',
                    slotLabelInterval: '01:00',
                    defaultTimedEventDuration: '00:45',
                    forceEventDuration: true,
                    slotLabelFormat: {hour: '2-digit', minute: '2-digit'},
                    scrollTime: '05:00:00',
                    scrollTimeReset: false,
                    slotDuration: '00:15',
                    allDaySlot: false,
                    locale: deLocale,
                    editable: true,
                    droppable: true,
                    selectable: false,
                    height: 'auto',
                    nowIndicator: true,
                    selectMirror: true,
                    eventWillUnmount: ({event, el}) => {
                        this.tooltipCleanup?.()
                        this.tooltipCleanup = null;
                    },
                    events: (param, success) => {
                        // @ts-ignore
                        success(this.allEvents())
                    },
                    eventDragStart: () => this.isDragging = true,
                    eventDragStop: ({el}) => {
                        this.isDragging = false;
                        this.updateTooltip(el, this.$refs.tooltip as HTMLElement, this.$refs.arrow as HTMLElement)
                    },
                    eventDrop: ({event, relatedEvents, el, delta, revert}) => {
                        if (this.selectedEvent === null || (this.modifiedEvents.length === 0 && this.creationEvent === null)) {
                            const mainEvent = [event, ...relatedEvents].find((currentEvent) => !('isSubEvent' in currentEvent.extendedProps && currentEvent.extendedProps.isSubEvent))
                            this.tooltipCleanup?.();
                            this.tooltipCleanup = null;
                            const currentEvent = this.events.find((currentEvent) => currentEvent.id === mainEvent.id)
                            if (!currentEvent) {
                                revert();
                                return;
                            }

                            if(!('moveConfirmationComponent' in currentEvent)) {
                                revert();
                                return;
                            }

                            this.revertLastMovement = revert;
                            this.$dispatch('openModal', {
                                component: currentEvent.moveConfirmationComponent.name,
                                arguments: {
                                    start: mainEvent.startStr,
                                    end: mainEvent.endStr,
                                    ...currentEvent.moveConfirmationComponent.arguments
                                }
                            })
                            return;
                        }
                        const selectedCalendarEvent = [event, ...relatedEvents].find((currentEvent) => currentEvent.id === this.selectedEvent?.id)

                        if (!selectedCalendarEvent) {
                            revert();
                            this.deselectEvent()
                            return;
                        }
                        this.$dispatch('update-calendar-event-start', {
                            start: selectedCalendarEvent.start,
                        })
                    },
                    dateClick: ({dateStr, date, ...params}) => {
                        if (this.selectedEvent) {
                            this.deselectEvent()
                            calendar?.refetchEvents()
                            return;
                        }

                        this.createEvent({
                            id: 'new-event',
                            start: date.toISOString() as DateTimeString,
                            end: addMinutes(date, 45).toISOString() as DateTimeString,
                            type: 'new-event',
                            appointmentOwner: {
                                id: this.teachers[0]
                                    ? parseInt(this.teachers[0] as string)
                                    : this.cars[0]
                                        ? parseInt(this.cars[0] as string)
                                        : -1,
                                type: this.teachers.length === 0 && this.cars.length > 0 ? 'car' : 'user',
                            },
                            events: [],
                        });
                    },
                    eventClick: (param) => {
                        const event = this.getEventById(param.event.groupId || param.event.id);
                        if (!event) {
                            console.error('Event not found', param.event.groupId ?? param.event.id)
                            return;
                        }

                        if (event.type === 'worktime') {
                            return;
                        }

                        if (event.id === this.selectedEvent?.id) {
                            this.deselectEvent()
                            return;
                        }
                        // @ts-ignore
                        this.selectedEventId = event.id;
                        this.updateTooltip(param.el, this.$refs.tooltip as HTMLElement, this.$refs.arrow as HTMLElement)

                        this.openOverviewTooltip(event)
                        if(this.modifiedEvents.length > 0 || this.creationEvent) {
                            this.modifiedEvents = [];
                            this.creationEvent = null;
                            calendar?.refetchEvents()
                        }



                    },
                    eventContent: ({event: {start, end, id, startStr, ...params}, view}) => {
                        const event = this.getEventById(id);
                        if (!event || event?.type === 'worktime') {
                            return <></>
                        }
                        const indicators = getIndicators(event);

                        if(this.anonymized) {
                            return <>
                                <p className="text-xs">{getTime(start)} - {getTime(end)}</p>
                            </>
                        }

                        return <>
                        <div className="flex items-center justify-end absolute gap-x-0.5 -top-1 -right-2">
                                {indicators.map(indicator => indicator)}
                            </div>
                            <p className="text-xs">{event.title}</p>
                            <p className="text-xs">{getTime(start)} - {getTime(end)}</p>
                            {('label' in event) && Array.isArray(event.label) && event.label.map(label => <p
                                class="text-xs">{label}</p>)}
                        </>
                    },
                    eventDidMount: ({event, el}) => {
                        if (this.selectedEvent?.id !== event.id) {
                            return;
                        }

                        this.updateTooltip(el, this.$refs.tooltip as HTMLElement, this.$refs.arrow as HTMLElement)

                        this.$nextTick(() => {
                            el.scrollIntoView({behavior: 'smooth', block: 'center'});
                        })
                    },
                } satisfies CalendarOptions
            }
        }
    }
)

function removeNotSelectedCarEvents(events: CalendarEvent[], cars: Filters['cars']) {
    return events.filter(event => event.appointmentOwner.type !== 'car' || cars.find((carId) => parseInt(carId as string) === event.appointmentOwner.id))
}

async function fetchEvents(start: Date, end: Date, filters: Pick<Filters, 'teachers' | 'cars'>): Promise<CalendarEvent[]> {
    const response = await fetch(buildEventFetchUrl(start.toISOString(), addWeeks(end, 2).toISOString(), filters))

    if (!response.ok) {
        throw new Error('Error fetching events');
    }

    return response.json();
}


function buildEventFetchUrl(start: string, end: string, filters: Pick<Filters, 'teachers' | 'cars'>) {
    const urlParams = new URLSearchParams()
    urlParams.set('start', start)
    urlParams.set('end', end)
    filters.teachers.forEach(teacher => urlParams.append('teachers[]', String(teacher)))
    filters.cars.forEach(car => urlParams.append('cars[]', String(car)))

    return `/ajax/calendar/events?${urlParams.toString()}`;
}


function getTime(date: Date | null) {
    if (!date) {
        return '';
    }
    const time = date.toUTCString().match(/((\d{2}:\d{2}):\d{2})/);

    if (time === null) {
        return '';
    }
    return time[2] as unknown as string;
}

function getIndicators(event: CalendarEvent): ComponentChildren[] {
    const indicators: ComponentChildren[] = [];

    if(!('indicators' in event) || !Array.isArray(event.indicators)) {
        return indicators;
    }

    if(event.indicators.includes('provisional')) {
        indicators.push(<div className="w-2 h-2 border border-white bg-gray-900 rounded-full"></div>)
    }

    if(event.indicators.includes('trial-lesson')) {
        indicators.push(<div className="w-2 h-2 border border-white bg-green-500 rounded-full"></div>)
    }

    if(event.indicators.includes('first-lesson')) {
        indicators.push(<div className="w-2 h-2 border border-white bg-yellow-400 rounded-full"></div>)
    }

    if(event.indicators.includes('custom-address')) {
        indicators.push(<div className="w-2 h-2 border border-white bg-black rounded-full"></div>)
    }

    if(event.indicators.includes('exam')) {
        indicators.push(<div className="w-2 h-2 border border-white bg-blue-500 rounded-full"></div>)
    }

    return indicators;
}

function setupTooltip(anchor: HTMLElement, tooltip: HTMLElement, arrowElement: HTMLElement) {
    return autoUpdate(
        anchor,
        tooltip,
        () => computePosition(anchor, tooltip, {
            middleware: [autoPlacement({
                allowedPlacements: ['left', 'right']
            }), shift(), offset(10), arrow({element: arrowElement})]
        })
            .then(({x, y, middlewareData, placement}) => {
                Object.assign(tooltip.style, {
                    left: `${x}px`,
                    top: `${y}px`
                })

                if (!middlewareData.arrow) {
                    return;
                }
                const {x: xArrow, y: yArrow} = middlewareData.arrow;

                const staticPosition = placement.split('-')[0] as 'top' | 'right' | 'bottom' | 'left'

                const staticSide = {
                    top: 'bottom',
                    right: 'left',
                    bottom: 'top',
                    left: 'right',
                }[staticPosition];

                Object.assign(arrowElement.style, {
                    'top': yArrow != null ? `${yArrow}px` : '',
                    'left': xArrow != null ? `${xArrow}px` : '',
                    [staticSide]: '-8px',
                });
            })
    )
}
