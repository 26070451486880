import {setupComboboxFloating} from "./utils/floating";
import {Alpine as LivewireAlpine} from "../../vendor/livewire/livewire/dist/livewire.esm";
import type {Alpine as AlpineType} from "alpinejs";
import {array, Input, number, object, string, union} from "valibot";
import Fuse from "fuse.js";
import {isBackspace} from "./utils/keyboard";



const Alpine: AlpineType = LivewireAlpine

const OptionSchema = object({
    id: union([string(), number()]),
    name: string(),
})
const OptionsSchema = object({
    options: array(OptionSchema)
})

type Option = Input<typeof OptionSchema>

Alpine.data('multiCombobox', ({options}: {options: Option[]}) => {
    let fuse: Fuse<Option>|null = null;



    return {
        isOpen: false,
        query: '',
        options: options,
        activeIndex: -1,
        selected: [] as Option['id'][],
        keyboardNavigationEnabled: true,
        cleanupTooltip: null as (() => void) | null,

        init() {
            fuse = new Fuse(options, {
                keys: ['name'],
                threshold: 0.3,
            })
            this.$watch('activeIndex', (value) => {
                if (value < 0) {
                    return;
                }
                if(!this.keyboardNavigationEnabled) {
                    return;
                }
                this.$nextTick(() => {
                    // no idea why it needs to be + 1 here, but otherwise it is the wrong index
                    const activeElement = this.$refs.results?.children[value + 1];
                    if (!activeElement) {
                        return;
                    }
                    activeElement.scrollIntoView({block: "nearest"})
                })
            })
        },

        disableKeyboardNavigation() {
            this.keyboardNavigationEnabled = false;
        },
        enableKeyboardNavigation() {
            this.keyboardNavigationEnabled = true;
        },
        dispatchBlur() {
            this.$nextTick(() => {
                if (this.$root.contains(document.activeElement)) {
                    return;
                }

                this.$root.dispatchEvent(new Event('blur'));
            });
        },
        search(event: Event) {
            if(!(event.target instanceof HTMLInputElement)) {
                console.error('Search method can only be used on an input element', event.target);
                return;
            }
            this.isOpen = true;
            this.setupFloating(this.$root, this.$refs.results as HTMLElement)
            this.activeIndex = 0;

            this.query = event.target.value;
        },
        handleBackspace(event: KeyboardEvent) {
            if (event.code !== 'Backspace' && (!event.keyCode || !isBackspace(event.keyCode))) {
                return true;
            }

            if (this.query.length > 0) {
                return true;
            }

            if (this.selected.length === 0) {
                return true;
            }
            this.selected.pop();
            this.query = '';
        },
        showOptions() {
            this.isOpen = true;
            this.setupFloating(this.$root, this.$refs.results as HTMLElement)
            this.$nextTick(() => {
                // For some reason the input is not always focuses without the timeout
                setTimeout(() => {
                    this.$refs.input?.focus()
                }, 100)
            });
        },
        optionById(id: Option['id']) {
            return this.options.find(option => String(option.id) === String(id));
        },
        setupFloating(anchorElement: HTMLElement, optionsElement: HTMLElement) {
            if(this.cleanupTooltip) {
                return;
            }
            this.cleanupTooltip = setupComboboxFloating(anchorElement, optionsElement);
        },

        get isEmpty() {
            return this.selected.length === 0;
        },
        get selectedOptions() {
            return this.options.filter(option => this.selected.find((optionId) => String(optionId) === String(option.id)));
        },
        get displaySelectedOptions() {
            if (this.selectedOptions.length > 1 && this.isOpen) {
                return this.selectedOptions.slice(0, 1).map(option => option.name).join(', ') + '+ ' + (this.selectedOptions.length - 1);
            }

            return this.selectedOptions.map(option => option.name).join(', ');
        },
        closeOptions() {
            this.query = '';
            this.isOpen = false;
            this.cleanupTooltip?.();
            this.cleanupTooltip = null;
        },
        isSelected(option: Option) {
            return this.selected.find(selectedId => String(selectedId) === String(option.id)) !== undefined;
        },
        clear() {
            this.selected = [];
            this.isOpen = false;
            this.cleanupTooltip?.();
            this.cleanupTooltip = null;
            this.activeIndex = -1;
            this.query = '';
        },
        selectOption() {
            const selectedOption = this.filteredOptions[this.activeIndex];
            if(!selectedOption) {
                return;
            }

            if (this.isSelected(selectedOption)) {
                this.selected = this.selected.filter(selectedId => selectedId !== selectedOption.id)
            } else {
                this.selected.push(selectedOption.id)
            }
            this.$refs.input?.focus();
            this.$root.dispatchEvent(new Event('change', {
                detail: {
                    value: this.selected
                }
            } as EventInit))
        },
        removeOption(option: Option) {
            this.selected = this.selected.filter(selectedId => String(selectedId) !== String(option.id))
        },
        closeOnFocusOut() {
            this.$nextTick(() => {
                if (this.$root.contains(document.activeElement)) {
                    return;
                }

                if(this.$refs.results === document.activeElement) {
                    return;
                }

                if(this.$refs.results?.contains(document.activeElement)) {
                    return;
                }

                if (!this.isOpen) {
                    return;
                }

                this.closeOptions()
            });
        },
        get filteredOptions() {
            if(!this.query || !fuse) {
                return this.options
            }

            return fuse.search(this.query).map(result => result.item);
        },
        selectPrevious() {
            if (!this.keyboardNavigationEnabled) {
                return;
            }
            if (this.activeIndex > 0) {
                this.activeIndex--
            }
        },
        selectNext() {
            if (!this.keyboardNavigationEnabled) {
                return;
            }
            if (this.activeIndex < this.filteredOptions.length - 1) {
                this.activeIndex++
            }
        },
    };
})
