import {arrow, autoUpdate, computePosition, flip, offset} from "@floating-ui/dom";

window.number_format = function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

window.absenceTooltipInfoData = function absenceTooltipInfoData() {
    return {
        popperInstance: null,
        reference: null,
        tooltip: null,
        init() {
            let $this = this;
            window.addEventListener('open-absence-tooltip', function (event) {
                if ($this.isTooltipShown()) {
                    return;
                }

                $this.reference = document.getElementById(event.detail);
                $this.tooltip = document.getElementById('absence-tooltip');
                $this.popperInstance = createPopper($this.reference, $this.tooltip, {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                    ],
                });

                setTimeout(function () {
                    $this.show(event.detail);
                }, 500);
            });

            window.addEventListener('click', function (event) {
                $this.checkTooltipState(event);
            })
        },
        isTooltipShown() {
            if (!this.tooltip) {
                return false;
            }

            return this.tooltip.hasAttribute('data-show');
        },
        show(date) {
            this.tooltip.setAttribute('data-show', '');
            this.popperInstance.update();
            Livewire.dispatch('openAbsenceTooltip', {date: date})
        },
        hide() {
            this.tooltip.removeAttribute('data-show');
            Livewire.dispatch('closeAbsenceTooltip')
        },
        checkTooltipState(event) {
            if (!this.tooltip) {
                return;
            }

            if (!this.tooltip.hasAttribute('data-show')) {
                return;
            }

            let el = document.querySelector('#absence-tooltip');
            if (el && el.contains(event.target)) {
                return;
            }

            this.hide();
        }
    }
}


window.uploadImageFromClipboard = async (componentId, filename, onSuccessMethod = null) => {
    const clipboardItems = await navigator.clipboard.read();
    const component = Livewire.find(componentId);

    if (!component) {
        return;
    }

    for (const clipboardItem of clipboardItems) {

        for (const type of clipboardItem.types) {
            if (type.includes('image')) {
                let item = await clipboardItem.getType(type);

                component.upload(filename, item, () => {
                    if (onSuccessMethod) {
                        component.$wire.call(onSuccessMethod);
                    }
                });
                break;
            }
        }
    }
}

window.copyToClipboard = (text) => {
    let url = document.body.appendChild(document.createElement("input"));
    url.value = text;
    url.focus();
    url.select();
    document.execCommand('copy');
    url.parentNode.removeChild(url);
}


//open image modal
document.addEventListener('click', function (event) {
    if (event.target.hasAttribute('data-full-preview')) {
        openImageModal(event.target.getAttribute('src'));
    }
});

window.openImageModal = (src) => {
    window.dispatchEvent(new CustomEvent("open-image-modal", {
        detail: src
    }));
}

window.open = function (open) {
    return function (url, ...args) {
        if(!url) {
            return;
        }

        return open.call(window, url, ...args);
    };
}(window.open);


//Select Teachers
window.selectTeachersData = ({component, itemsField, field}) => {

    const items = component.$entangle(itemsField);

    return {
        selected: component.$entangle(field, true),
        open: false,
        sorted: items,
        items: items,
        query: '',
        hoverIndex: -1,

        mounted() {
            this.$watch('query', (value) => {
                this.hoverIndex = -1;
            });

            this.updateSortArray();
        },

        updateSortArray() {
            this.sorted = [...this.items]
                .sort((a, b) => {
                    let isASelected = this.isSelected(a.id);
                    let isBSelected = this.isSelected(b.id);

                    if (!isASelected && isBSelected) {
                        return 1;
                    }
                    if (isASelected && !isBSelected) {
                        return -1;
                    }

                    return 0;
                })
        },

        onEnter() {
            if (this.hoverIndex === null) {
                return;
            }

            if (this.hoverIndex === -1) {
                this.hoverIndex = 0;
            }

            const item = this.filtered().find((item, index) => index === this.hoverIndex);
            if (!item) {
                return;
            }

            this.isSelected(item.id)
                ? this.removeItem(item.id)
                : this.addItem(item.id);
        },

        onKeyup() {
            this.open = true;

            this.hoverIndex = Math.max(0, this.hoverIndex - 1)

            this.$nextTick(() => {
                this.$refs.dropdown.children[this.hoverIndex + 1].scrollIntoView({
                    block: 'nearest',
                })
            })
        },

        onKeydown() {
            this.open = true;

            this.hoverIndex = Math.min(this.filtered().length - 1, this.hoverIndex + 1)

            this.$nextTick(() => {
                this.$refs.dropdown.children[this.hoverIndex + 1].scrollIntoView({
                    block: 'nearest',
                })
            })
        },

        toggleDropdown() {
            this.open ? this.closeDropdown() : this.openDropdown();
        },

        openDropdown() {
            this.query = '';

            this.open = true;

            this.updateSortArray();

            setTimeout(() => {
                this.$refs.input.focus();
                this.$refs.dropdown.scrollTo({top: 0});
            }, 100)
        },

        closeDropdown() {
            this.open = false;
        },

        isSelected(id) {
            return this.selected.find((selectedId) => id == selectedId);
        },

        removeItem(itemId) {
            this.selected = this.selected.filter((selectedId) => selectedId != itemId);
        },

        addItem(itemId) {
            this.selected.push(`${itemId}`)
        },

        filtered() {
            const sortedArray = [...this.sorted];

            return this.query === ''
                ? sortedArray
                : sortedArray
                    .filter((item) =>
                        this.unify(item.name)
                            .includes(this.unify(this.query))
                    );
        },

        unify(value) {
            return value
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
        },

        selectedItems() {
            return [...this.items].filter((item) => this.isSelected(item.id));
        }
    }
}

window.selectTaskAssignee = ({component, items, field, value = null}) => {
    let assigneeValue = field ? component.entangle(field).live : value;

    return {
        open: false,
        query: '',
        selectedValue: assigneeValue,
        hoverIndex: 0,
        items: items,
        filteredItems: [],

        init() {
            this.items.unshift({
                name: '–',
                id: null,
                image: '/img/icons/unknown-student.svg',
            })

            this.mountFilteredItems();

            this.$watch('query', () => this.mountFilteredItems());

            this.$watch('selectedValue', () => {
                this.closeDropdown();
                this.triggerChangeEvent();
                this.mountFilteredItems();
            })
        },

        isSelected(id) {
            return this.selectedValue === id;
        },

        get selectedItem() {
            return this.items.find(item => this.isSelected(item.id));
        },

        toggleDropdown() {
            this.open ? this.closeDropdown() : this.openDropdown();
        },

        openDropdown() {
            this.open = true;
            this.query = '';

            setTimeout(() => {
                this.$refs.input.focus();
            }, 100);
        },

        closeDropdown() {
            this.open = false;
            this.query = '';
        },

        resetSelectedValue() {
            this.selectedValue = null;
            this.query = "";
            this.open = false;
            this.hoverIndex = -1;
        },

        select(itemId) {
            if (itemId) {
                this.selectedValue = itemId;
                return;
            }

            let item = this.filteredItems[this.hoverIndex] ?? null;
            if (item) {
                this.selectedValue = item.id;
            }
        },

        triggerChangeEvent() {
            this.$refs.results.dispatchEvent(new CustomEvent('change', {detail: this.selectedValue}));
        },

        mountFilteredItems() {
            let items = this.query === ''
                ? this.items
                : this.items.filter((item) =>
                    item.name
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(this.query.toLowerCase().replace(/\s+/g, ''))
                )

            this.filteredItems = items.slice(0, 100);
        },

        onKeyup() {
            this.open = true;

            this.hoverIndex = Math.max(0, this.hoverIndex - 1)

            this.$nextTick(() => {
                this.$refs.results.children[this.hoverIndex + 1].scrollIntoView({
                    block: 'nearest',
                })
            })
        },

        onKeydown() {
            this.open = true;

            this.hoverIndex = Math.min(this.filteredItems.length - 1, this.hoverIndex + 1)

            this.$nextTick(() => {
                this.$refs.results.children[this.hoverIndex + 1].scrollIntoView({
                    block: 'nearest',
                })
            })
        },
    }
};


window.commentsData = ({component}) => {
    return {
        isWindows: false,
        isMac: false,
        init() {
            if (navigator.appVersion.indexOf("Win") !== -1) {
                this.isWindows = true;
            }

            if (navigator.appVersion.indexOf("Mac") !== -1) {
                this.isMac = true;
            }
        },
        submit(e, method = 'submit') {
            if (this.isWindows && e.keyCode === 13 && e.altKey) {
                component.$wire.call(method)
                return true;
            }

            if (this.isMac && (e.metaKey || e.ctrlKey) && e.keyCode === 13) {
                component.$wire.call(method)
                return true;
            }
        },
    }
}

window.openTask = (taskId, taskContext = null) => {
    if (event.metaKey) {
        const url = new URL(window.location.href);
        url.searchParams.append('showTask', taskId)
        url.searchParams.append('taskContext', taskContext)
        window.open(url.toString(), '_blank').focus();
    } else {
        window.dispatchEvent(new CustomEvent('open-task', {
            detail: {
                id: taskId,
                context: taskContext
            }
        }))
    }
}

window.markText = (element) => {
    var range, selection;

    if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}
