import * as Sentry from "@sentry/browser";

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

if (sentryDsn) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.replayIntegration(),
        ],
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
    });

    if(typeof window.authenticatedUser === 'object'){
        Sentry.setUser({
            slug: window.authenticatedUser.slug,
            id: window.authenticatedUser.id,
            email: window.authenticatedUser.email,
            username: window.authenticatedUser.name,
        })
    }


} else {
    console.error('Sentry deactivated. Please set VITE_SENTRY_DSN in .env');
}
