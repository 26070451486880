import {autoUpdate, computePosition, flip} from "@floating-ui/dom";

export function setupComboboxFloating(anchorElement: HTMLElement, optionsElement: HTMLElement) {
    if(!anchorElement || !optionsElement) {
        console.error('Combobox floating setup failed', {anchorElement, optionsElement})
    }
    return autoUpdate(anchorElement, optionsElement, () => computePosition(anchorElement, optionsElement, {
        placement: 'bottom-start',
        middleware: [
            flip(),
        ]
    }).then(({x, y}) => {
        Object.assign(optionsElement?.style || {}, {
            top: `${y}px`,
            left: `${x}px`,
        })
    }));
}
