export default function videoUpload({state, uploadVideoFromUrl, getVideoInformation}) {
    return {
        state,
        videoUrl: '',
        thumbnail: '',
        loadingThumbnail: false,
        checkingThumbnailFn: null,
        init() {
            if(this.state) {
                getVideoInformation(this.state)
                    .then((video) => {
                        if(!video.id) {
                            return;
                        }
                        this.videoUrl = video.url;
                        this.checkThumbnailState(video.thumbnail)
                    })
            }
        },

        async checkThumbnailState(thumbnailUrl) {
            if(!this.loadingThumbnail) {
                this.loadingThumbnail = true;
            }
            try {
                const response = await fetch(thumbnailUrl)
                if(response.status === 404) {
                    throw new Error('Thumbnail not found')
                }

                this.thumbnail = thumbnailUrl;
                this.loadingThumbnail = false;
            } catch (error) {
                this.thumbnail = '';
                this.checkingThumbnailFn = setTimeout(() => this.checkThumbnailState(thumbnailUrl), 2000);
            }
        },

        removeVideo() {
            clearTimeout(this.checkingThumbnailFn)
            this.checkingThumbnailFn = null;
            this.state = null;
            this.thumbnail = '';
            this.loadingThumbnail = false;
        },

        destroy() {
            clearTimeout(this.checkingThumbnailFn)
        },

        input: {
            ['@input'](event) {
                this.videoUrl = event.target.value;
                debounce(async function () {
                    this.dispatchFormEvent('form-processing-started', {
                        message: 'Video Informationen abrufen...',
                    })
                    const video = await uploadVideoFromUrl(event.target.value);
                    this.dispatchFormEvent('form-processing-finished')
                    if(!video) {
                        return;
                    }
                    this.state = video.id;
                    this.checkThumbnailState(video.thumbnail)
                }.bind(this), 500)();
            },
            ':value': 'videoUrl',
        },
        dispatchFormEvent: function (name, detail = {}) {
            this.$el.closest('form')?.dispatchEvent(
                new CustomEvent(name, {
                    composed: true,
                    cancelable: true,
                    detail,
                }),
            )
        },
    }
}


function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}
