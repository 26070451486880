window.selectStudent = (config) => {
    return {
        queryResultsList: window.Livewire.find(config.componentId).entangle('students'),
        selectedStudent: window.Livewire.find(config.componentId).entangle('selectedStudent'),

        selected: 0,
        isOpen: false,
        input: '',

        init() {
            this.$watch('input', async value => {
                await this.$wire.search(value)
                this.selected = 0;
            });

            this.$watch('selectedStudent', value => {
                this.closeDropdown();
            });
        },

        filteredItems() {
            if (this.queryResultsList && this.queryResultsList.length) {
                return this.queryResultsList
            }
            return [];
        },

        selectUp() {
            this.selected = Math.max(0, this.selected - 1)
            this.$nextTick(() => {
                this.$refs.results.children[this.selected + 1].scrollIntoView({
                    block: 'nearest',
                })
            })
        },

        selectDown() {
            this.selected = Math.min(this.filteredItems().length - 1, this.selected + 1)
            this.$nextTick(() => {
                this.$refs.results.children[this.selected + 1].scrollIntoView({
                    block: 'nearest',
                })
            })
        },

        select(item) {

            if (!item) {
                item = this.filteredItems()[this.selected]
            }

            if (item.id) {
                this.$wire.setStudent(item.id)
            }
        },

        openDropdown() {
            this.isOpen = true;

            setTimeout(() => {
                this.$refs.input.focus()
            }, 100);
        },

        closeDropdown() {
            this.isOpen = false;
        }
    };
};
