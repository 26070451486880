import Fuse from "fuse.js";
import {Alpine as LivewireAlpine} from "../../vendor/livewire/livewire/dist/livewire.esm";
import {Alpine as AlpineType, type AlpineComponent} from "alpinejs";
import {array, number, object, parse, string, union} from "valibot";
import {setupComboboxFloating} from "./utils/floating";


const Alpine: AlpineType = LivewireAlpine

const OptionSchema = object({
    id: union([string(), number()]),
    name: string(),
})
const OptionsSchema = object({
    options: array(OptionSchema)
})


Alpine.data('singleCombobox', (...parameters) => {
    const options = parse(OptionsSchema, parameters[0]).options;

    const fuse = new Fuse(options, {
        keys: ['name'],
        threshold: 0.3,
    })

    return {
        id: '',
        isOpen: false,
        query: '',
        options: options,
        activeIndex: -1,
        selected: null as string | number | null,
        keyboardNavigationEnabled: true,
        autoUpdateCleanup: null as (() => void) | null,

        init() {
            this.id = this.$id('single-combobox');
            this.query = this.selectedOption.name;
            this.$watch('selected', value => {
                if (value) {
                    this.query = this.selectedOption.name ?? '';
                }
            })

            this.$watch('isOpen', (value) => {
                if (value === false) {
                    return;
                }
                if (this.activeIndex < 0) {
                    return;
                }

                this.$nextTick(() => {
                    if (!this.$refs.results?.children) {
                        return;
                    }

                    this.$refs.results.children[this.activeIndex]?.scrollIntoView({block: "center"})
                })
            })
        },

        dispatchBlur() {
            this.$nextTick(() => {
                if (this.$root.contains(document.activeElement)) {
                    return;
                }

                this.$root.dispatchEvent(new Event('blur'));
            });
        },
        disableKeyboardNavigation() {
            this.keyboardNavigationEnabled = false;
        },
        enableKeyboardNavigation() {
            this.keyboardNavigationEnabled = true;
        },

        search(event: Event) {
            if (!(event.target instanceof HTMLInputElement)) {
                throw new Error('Can only run search() on an input element.')
            }
            this.isOpen = true;
            this.query = event.target.value;
            this.activeIndex = 0;
        },
        showOptions() {
            this.positionOptions();
            this.isOpen = true;
            this.$refs.input?.focus();
        },
        showAllOptions() {
            this.positionOptions();
            this.query = '';
            this.isOpen = true;
            this.$refs.input?.focus();
            this.activeIndex = this.options.findIndex((option) => option.id == this.selected);
        },

        positionOptions() {
            if (this.autoUpdateCleanup !== null) {
                this.autoUpdateCleanup();
            }

            const anchorElement = this.$root as HTMLElement
            const optionsElement = this.$refs.results as HTMLElement
            this.autoUpdateCleanup = setupComboboxFloating(anchorElement, optionsElement)
        },
        get selectedOption() {
            return this.options.find(option => option.id == this.selected) ?? {
                id: '',
                name: ''
            };
        },
        closeOptions() {
            this.query = this.selectedOption.name ?? '';
            this.isOpen = false;
            if (this.autoUpdateCleanup !== null) {
                this.autoUpdateCleanup();
            }
        },
        isSelected(option: { id: string | number | null }) {
            return option.id === this.selected;
        },
        clear() {
            this.selected = null;
            this.isOpen = false;
            this.activeIndex = -1;
            this.query = '';
        },
        selectOption() {
            const selectedOption = this.filteredOptions[this.activeIndex];
            this.selected = selectedOption?.id ?? null;
            this.$refs.input?.focus();
            this.$root.dispatchEvent(new Event('change', {
                detail: {
                    value: this.selected
                }
            } as EventInit))
            this.closeOptions();
        },
        closeOnFocusOut() {
            this.$nextTick(() => {
                if (this.$root.contains(document.activeElement)) {
                    return;
                }

                if (!this.isOpen) {
                    return;
                }

                this.closeOptions()
            });

        },
        get filteredOptions() {
            if (!this.query) {
                return this.options
            }

            return fuse.search(this.query).map(result => result.item);
        },
        selectPrevious() {
            if (!this.keyboardNavigationEnabled) {
                return;
            }
            if (this.activeIndex > 0) {
                this.activeIndex--
            }

            const prevIndex = this.activeIndex - 2;
            if (!this.$refs.results?.children[prevIndex]) {
                return;
            }
            this.$refs.results?.children[prevIndex]?.scrollIntoView({block: "nearest"})
        },
        selectNext() {
            if (!this.keyboardNavigationEnabled) {
                return;
            }
            if (this.activeIndex < this.filteredOptions.length - 1) {
                this.activeIndex++
            }

            const nextIndex = this.activeIndex + 2;
            if (!this.$refs.results?.children[nextIndex]) {
                return;
            }
            this.$refs.results?.children[nextIndex]?.scrollIntoView({block: "nearest"})
        },

        searchInput: (...parameters: unknown[]) => {
            return {
                ':value': 'query',
                '@input': 'search',
                'x-ref': 'input',
                '@click': 'showOptions',
                '@focusIn': 'showOptions',
                '@focusout': 'closeOnFocusOut',
                '@blur': 'dispatchBlur',
                ['@keydown.prevent.arrow-up']: 'selectPrevious',
                ['@keydown.prevent.arrow-down']: 'selectNext',
                ['@keydown.prevent.enter']: 'selectOption',
                ['@keydown.esc']: 'closeOption',
                role: 'presentation'
            }
        },

        optionList: (...parameters: unknown[]) => {
            return {
                'x-show': 'filteredOptions.length > 0 && isOpen',
                'x-cloak': '',
                '@mouseenter': 'disableKeyboardNavigation',
                '@mouseleave': 'enableKeyboardNavigation',
                'x-ref': 'results',
                [':id'](this: AlpineComponent<any>) {
                    return this.id + '-options';
                },
                [':data-combobox-state'](this: AlpineComponent<any>) {
                    return this.isOpen ? 'open' : 'closed';
                }
            }
        },
    };
})
