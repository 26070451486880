import {setupComboboxFloating} from "./utils/floating.ts";

Alpine.data('singleSearchInput', ({getOptions, preload}) => {
    return {
        isOpen: false,
        query: '',
        options: [],
        activeIndex: -1,
        selected: null,
        keyboardNavigationEnabled: true,
        isLoading: false,
        showLoading: false,
        searchCache: new Map(),
        cleanupTooltip: null,

        async init() {
            this.$nextTick(async () => {
                if (!this.selected) {
                    this.options = await preload();
                } else {
                    this.options = await this.runSearch('', this.selected);
                }
            })
            this.$watch('selected', (value) => {
                if (!value) {
                    return;
                }
                this
                    .runSearch('', value)
                    .then(options => {
                        this.options = options;
                    });
            })
            this.$watch('activeIndex', (value) => {
                if (value < 0) {
                    return;
                }
                this.$nextTick(() => {
                    if (!this.$refs.results.children[value + 1]) {
                        return;
                    }

                    this.$refs.results.children[value + 1].scrollIntoView({block: "nearest"})
                })
            })
        },

        dispatchBlur() {
            this.$nextTick(() => {
                if (this.$root.contains(document.activeElement)) {
                    return;
                }

                this.$root.dispatchEvent(new Event('blur'));
            });
        },
        disableKeyboardNavigation() {
            this.keyboardNavigationEnabled = false;
        },
        enableKeyboardNavigation() {
            this.keyboardNavigationEnabled = true;
        },

        search(event) {
            this.isOpen = true;
            this.setupFloating(this.$root, this.$refs.results)
            this.query = event.target.value;
            this.activeIndex = 0;
            this.runSearch(event.target.value)
                .then(options => {
                    this.options = options;
                });
        },

        setupFloating(anchorElement, optionsElement) {
            if (this.cleanupTooltip) {
                return;
            }
            this.cleanupTooltip = setupComboboxFloating(anchorElement, optionsElement);
        },

        get displayValue() {
            return this.isOpen ? this.query : this.selectedOption.name;
        },

        searchCacheKey(query, selectedId) {
            if (query) {
                return 'query-' + query;
            }

            if (selectedId) {
                return 'student-' + selectedId;
            }

            return null;
        },

        async runSearch(query, selectedId = null) {
            const cacheKey = this.searchCacheKey(query, selectedId);
            if (this.searchCache.has(cacheKey)) {
                return this.searchCache.get(cacheKey)
            }
            this.isLoading = true;
            const timeout = setTimeout(() => {
                this.showLoading = true
            }, 300);

            try {
                const options = await getOptions(query, selectedId);
                this.searchCache.set(cacheKey, options)

                return options;
            } finally {
                clearTimeout(timeout);
                this.showLoading = false;
                this.isLoading = false;
            }
        },
        showOptions() {
            this.isOpen = true;
            this.setupFloating(this.$root, this.$refs.results)
            this.$refs.input.focus();
        },
        get selectedOption() {
            return this.options.find(option => option.id == this.selected) ?? {
                id: '',
                name: this.selected,
            };
        },
        closeOptions() {
            this.query = '';
            this.isOpen = false;
            this.cleanupTooltip?.();
            this.cleanupTooltip = null;
        },
        isSelected(option) {
            return option.id === this.selected;
        },
        clear() {
            this.selected = null;
            this.isOpen = false;
            this.activeIndex = -1;
            this.query = '';
            this.cleanupTooltip?.();
            this.cleanupTooltip = null;
        },
        selectOption() {
            const selectedOption = this.options[this.activeIndex];
            this.selected = selectedOption.id;
            this.$refs.input.focus();
            this.$root.dispatchEvent(new Event('change', {
                detail: {
                    value: this.selected
                }
            }))
            this.closeOptions();
        },
        closeOnFocusOut() {
            this.$nextTick(() => {
                if (this.$root.contains(document.activeElement)) {
                    return;
                }

                if (this.$refs.results === document.activeElement) {
                    return;
                }

                if (this.$refs.results.contains(document.activeElement)) {
                    return;
                }

                if (!this.isOpen) {
                    return;
                }

                this.closeOptions()
            });

        },
        selectPrevious() {
            if (!this.keyboardNavigationEnabled) {
                return;
            }
            if (this.activeIndex > 0) {
                this.activeIndex--
            }
        },
        selectNext() {
            if (!this.keyboardNavigationEnabled) {
                return;
            }
            if (this.activeIndex < this.options.length - 1) {
                this.activeIndex++
            }
        },
    };
})
